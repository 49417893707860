//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftBar from "@/components/left-bar/left-bar.vue";
import menuApi from "@/api/menu";
import yellowPageApi from "@/api/yellowPage";
import loginApi from "@/api/login";
import comment from "@/components/comment/comment";
import CustomerInfo from "@/components/customerInfo/customerInfo.vue";
function throttle(fun, delay) {
  let last, deferTimer;
  return function (args) {
    let that = this;
    let _args = args;
    let now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        fun.apply(that, _args);
      }, delay);
    } else {
      last = now;
      fun.apply(that, _args);
    }
  };
}
export default {
  components: {
    LeftBar,
  },
  data() {
    return {
      messageInfo: {},
      menulist: [],
      yellowList: [],
      currentItem: null,
      currentIndex: 0,
      action: {
        barShow: false,
        carouselShow: false,
      },
      type: null,
    };
  },
  mounted() {
    /**
     * 查询经典案例的菜单
     */
    this.getMenu();
  },
  computed: {
    staticApi() {
      return process.env.VUE_APP_STATIC_API;
    },
  },
  methods: {
    throttleArrowClick(val) {
      throttle(this.arrowClick(val), 2000)
    },
    arrowClick(val) {
      if (val === "next") {
        if (this.currentIndex != this.yellowList.length - 1)
          this.currentIndex = this.currentIndex + 1;
      } else {
        if (this.currentIndex != 0)
          this.currentIndex = this.currentIndex - 1;
      }
    },
    swipeIndexUp() {
      this.$nextTick(() => {
        this.$refs["carousel"].next();
      });
    },
    swipeIndexDown() {
      this.$nextTick(() => {
        this.$refs["carousel"].prev();
      });
    },
    carouselChange(e) {
      this.currentItem = this.fileList[e];
    },
    /**
     * 个人信息面板
     */
    showCustomerInfo() {
      let that = this;
      that.$layer.iframe({
        scrollbar: false,
        content: {
          content: CustomerInfo, //传递的组件对象
          parent: that, //当前的vue对象
          data: {
            close: () => {
              that.$layer.closeAll();
            },
          }, //props
        },
        shadeClose: false,
        // maxmin: true,//开启最大化最小化
        shade: true,
        resize: false, //是否允许拉伸，默认是不允许
        area: ["610px", "640px"],
        title: `个人信息`,
        cancel: () => { },
      });
    },
    clickDescribe() {
      let item = this.currentItem;
      let that = this;
      let user = this.$store.state.currentUser;
      console.log("item", item);
      loginApi.checkLogin().then(() => {
        if (user.customerName == null || user.customerName == "") {
          this.$message.error("请先维护个人信息");
          this.showCustomerInfo();
          return;
        }

        this.isInComment = true;

        that.$layer.iframe({
          scrollbar: false,
          content: {
            content: comment, //传递的组件对象
            parent: that, //当前的vue对象
            data: {
              item: { objectid: item.businessId, name: item.businessName },
              type: that.type,
            }, //props
          },
          shadeClose: false,
          // maxmin: true,//开启最大化最小化
          shade: false,
          resize: true, //是否允许拉伸，默认是不允许
          offset: [window.innerWidth - 250, window.innerHeight - 300],
          area: ["500px", "600px"],
          title: `留言`,
          cancel: () => {
            that.isInComment = false;
          },
        });
      });
    },
    getMenu() {
      menuApi.getYellowPageMenu().then((res) => {
        this.menulist = res;
        this.action.barShow = true;
        this.checkMenu(res[0]);
      });
    },
    checkMenu(e) {
      this.action.carouselShow = false;
      this.type = e;
      this.currentIndex = 0;
      yellowPageApi.getCase({ type: e }).then((res) => {
        this.yellowList = res;
        console.log(this.yellowList)
        this.currentIndex = 0;
        this.action.carouselShow = true;
      });
    },
    barAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    carouselAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
  },
};
