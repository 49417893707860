import request from '@/utils/request'
import qs from "qs"
export function getCase(params) {
  return request({
    url: 'api/front/yellowPage',
    method: 'get',
    params
  })
}

export default { getCase }
