//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["title", "list", "checkMenu"],
  data() {
    return {
      currentMenuIndex: 0,
    };
  },
  methods: {
    handleClickMenu(item,index) {
      console.log(index);
      this.currentMenuIndex = index;
      this.checkMenu(item);
    },
    // onCell(el, index) {
    //   this.anime({
    //     targets: el,
    //     backgroundColor: "#026eda",
    //     color: "#ffffff",
    //     easing: "linear",
    //     duration: 100,
    //     delay: 0,
    //   });
    // },
    // outCell(el, index) {
    //   this.anime({
    //     targets: el,
    //     backgroundColor: "#dae2f2",
    //     color: "#636363",
    //     easing: "linear",
    //     duration: 100,
    //     delay: 0,
    //   });
    // },
  },
};
